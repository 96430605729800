import { client } from '../../apollo/client';
import { get_locations_by_orgs } from '../../quries';
import { SET_CARDS, SET_CARD_BACK_DATA, UPDATE_CARD_BACK_DATA, SET_DECKS, SET_CREATED_CATEGORY, SET_CREATED_COMPONENT, SET_TOUR_POP_STATUS, SET_SHAREPOINT_FORM_SHEET, SET_SHAREPOINT_FORM_IMPORT, REMOVE_SHAREPOINT_FORM_IMPORT, SET_ORGANIZATION_TAGS, IMPORT_FORM_FIELD_ACTION, SET_ORG_PRODUCTS, SET_ORG_PRODUCT_ENGAGE_INTEGRATION, SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION, SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION, SET_ORG_PRODUCT_CRM_INTEGRATION } from './types';

export const setCardBackData = data => ({ type: SET_CARD_BACK_DATA, data });
export const updateCardBackData = data => ({ type: UPDATE_CARD_BACK_DATA, data });
export const setPrimaryDataByOrg = data => ({
  type: 'SET_PRIMARY_DATA_BY_ORG',
  data,
});

export const setGroups = data => ({ type: 'SET_GROUPS', data });
export const setIsLoading = data => ({ type: 'SET_LOADING', data });
export const setChannels = data => ({ type: 'SET_CHANNELS', data });
export const setCategory = data => ({ type: 'SET_CAT', data });
export const setDecks = data => ({ type: SET_DECKS, data });
export const updateDeck = data => ({ type: 'UPDATE_DECK', data });
export const setCards = cards => ({ type: SET_CARDS, cards });
export const updateCards = cards => ({ type: 'UPDATE_CARDS', cards });
export const setOrg = data => ({ type: 'SET_ORG', data });
export const setOrgSettings = data => ({ type: 'SET_ORG_SETTINGS', data });
export const setJWPlayerData = data => ({ type: 'SET_JWPLAYER_DATA', data })
export const setCloudinarySettings = data => ({ type: 'SET_CLOUDINARY_DATA', data })
export const setFilestackData = data => ({ type: 'SET_FILESTACK_DATA', data })
export const setLibraryData = data => ({ type: 'SET_LIBRARY_DATA', data })
export const setSelectedChannel = data => ({
  type: 'SET_SELECTED_CHANNEL',
  data,
});
export const setSelectedGroup = data => ({
  type: 'SET_SELECTED_GROUP',
  data,
});
export const checkAndUpdatedSelectedChannel = data => ({
  type: 'CHECK_AND_UPDATE_SELECTED_CHANNEL',
  data,
});
export const toggleTheme = data => ({ type: 'TOGGLE_THEME', data });
export const setSearch = data => ({ type: 'SET_SEARCH_TEXT', data });
export const setSearchPreview = data => ({ type: 'SET_SEARCH_PREVIEW', data });
export const setBreadCrumb = data => ({ type: 'SET_BREADCRUMB', data });
export const showAlert = data => ({ type: 'SHOW_ALERT', data });
export const setQuestionCards = data => ({ type: 'SET_QUESTION_CARDS', data })
export const setUser = data => ({ type: 'SET_USER', data });

export const setCreatedCategory = data => ({ type: SET_CREATED_CATEGORY, data });
export const setCreatedComponent = data => ({ type: SET_CREATED_COMPONENT, data });
export const setIsTourPopStatus = data => ({ type: SET_TOUR_POP_STATUS, data });

export const setSharepointFormSheet = data => ({ type: SET_SHAREPOINT_FORM_SHEET, data });

export const setSharepointFormImportRequest = data => ({ type: SET_SHAREPOINT_FORM_IMPORT, data });
export const removeSharepointFormImportRequest = data => ({ type: REMOVE_SHAREPOINT_FORM_IMPORT, data });

export const setOrganizationTags = data => ({ type: SET_ORGANIZATION_TAGS, data });

export const refereshOrganizationTags = (dispatch) => {
  try {
    client.query({
      query: get_locations_by_orgs
    }).then(({ data }) => {
      dispatch(
        setOrganizationTags(data.get_locations_by_orgs)
      );
    }).catch(err => {
      console.log('1 fetchAndSetOrganizationTags error', err)
    })
  } catch (err) {
    console.log('fetchAndSetOrganizationTags error', err)
  }
}

export const importFormFieldAction = (data) => ({
  type: IMPORT_FORM_FIELD_ACTION,
  data,
});

export const setOrganizationProducts = data => ({ type: SET_ORG_PRODUCTS, data });
export const setOrganizationProductEngageIntegration = data => ({ type: SET_ORG_PRODUCT_ENGAGE_INTEGRATION, data });
export const setOrganizationProductDataCollectionIntegration = data => ({ type: SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION, data });
export const setOrganizationProductBreakthroughIntegration = data => ({ type: SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION, data });
export const setOrganizationProductCRMIntegration = data => ({ type: SET_ORG_PRODUCT_CRM_INTEGRATION, data });