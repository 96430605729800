export const SET_CARDS = 'SET_CARDS';
export const SET_CARD_BACK_DATA = 'SET_CARD_BACK_DATA';
export const UPDATE_CARD_BACK_DATA = 'UPDATE_CARD_BACK_DATA';
export const SET_DECKS = 'SET_DECKS';

export const SET_CREATED_CATEGORY = 'SET_CREATED_CATEGORY';
export const SET_CREATED_COMPONENT = 'SET_CREATED_COMPONENT';
export const SET_TOUR_POP_STATUS = 'SET_TOUR_POP_STATUS';

export const SET_SHAREPOINT_FORM_SHEET = 'SET_SHAREPOINT_FORM_SHEET';

export const SET_SHAREPOINT_FORM_IMPORT = 'SET_SHAREPOINT_FORM_IMPORT';
export const REMOVE_SHAREPOINT_FORM_IMPORT = 'REMOVE_SHAREPOINT_FORM_IMPORT'

export const SET_ORGANIZATION_TAGS = 'SET_ORGANIZATION_TAGS'
export const IMPORT_FORM_FIELD_ACTION = 'IMPORT_FORM_FIELD_ACTION';

export const SET_ORG_PRODUCTS = 'SET_ORG_PRODUCTS';
export const SET_ORG_PRODUCT_ENGAGE_INTEGRATION = 'SET_ORG_PRODUCT_ENGAGE_INTEGRATION';
export const SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION = 'SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION';
export const SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION = 'SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION';
export const SET_ORG_PRODUCT_CRM_INTEGRATION = 'SET_ORG_PRODUCT_CRM_INTEGRATION';